<template>
    <div>
        <div class="container">
            <div class="d-flex flex-row justify-content-between">
                <div class="d-flex flex-column col-3 ml-2 container-upload-image mb-4">
                    <div class="mb-2">
                      <label class="p-0">Titulo</label>
                      <input
                          type="text"
                          v-model="description"
                          class="form-control"
                          placeholder="Clique aqui para aproveitar nossas promoções"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="p-0 m-0">Imagem</label>
                      <span class="text-small text-muted m-0 mb-2 p-0 title-upload-image">Faça upload da imagem para exibição no modal</span>
                      <input 
                          id="upload-image"
                          name="upload-image"
                          class="btn p-0 m-0 button-select-image"
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          @change="selectedImage()"
                        />
                      <span 
                          v-if="sizeOutRange"
                          class="text-small alert alert-warning p-1 mt-1 alert-upload-image"
                          role="alert" 
                      >
                          Tamanho máximo permitido: 5MB
                      </span>
                      <span
                          v-if="typeOutRange"
                          class="text-small alert alert-warning p-1 mt-1 alert-upload-image" 
                          role="alert"
                      >
                          Tipos permitidos: jpeg, jpg e png.
                      </span>
                    </div>
                    <div>
                      <label class="p-0 mt-2">Filtros</label>
                      <select
                        class="form-select form-control"
                        v-model="filterSelected"
                      >
                          <option selected value="">
                              Nenhum
                          </option>
                          <option :key="item.name"
                              v-for="item of filterTypes"
                              :value="item.value"
                          >
                              {{ item.name }}
                          </option>
                      </select>
                      <div v-if="filterSelected === 'companies'" class="d-flex justify-content-start align-items-center">
                        <input @change="selectRoleModal('admin')" type="checkbox" name="admin" id="admin-role">
                        <label for="admin-role" class="pt-1 pl-1">Admin</label>
                      </div>
                      <div v-if="filterSelected === 'companies'" class="d-flex justify-content-start align-items-center">
                        <input @change="selectRoleModal('operator')" type="checkbox" name="operator" id="operator-role">
                        <label for="operator-role" class="pt-1 pl-1">Operador</label>
                      </div>
                    </div>
                    <div v-if="filterSelected == 'plans'" class="d-flex flex-column mt-2">
                        <div class="mr-2" v-for="(plan, index) in plans" :key="index">
                            <input type="checkbox" :id="plan.name" :name="plan.name" :value="!plan.selected" v-model="plans[index].selected">
                            <label class="font-weight-normal ml-1" :for="plan.name">{{plan.name}}</label>
                        </div>
                    </div>
                    <select-companies 
                        v-else-if="filterSelected == 'companies'"
                        :companies_notification="companiesNotification"
                        @selected_companies="selectedCompanies($event)"
                    />
                    <div>
                      <label class="p-0 mt-2">Redirecionamento</label>
                      <input
                          type="text"
                          v-model="redirectTo"
                          class="form-control"
                          placeholder="https://www.exemplo.com.br/"
                      />
                    </div>
                    <div>
                      <label class="p-0 mt-2">Duração</label>
                      <div class="d-flex flex-row">
                        <label class="p-0 mt-2 mr-1">De:</label>
                        <div class="d-flex flex-column">
                          <datepicker v-model="startsIn" :input-class="'form-control bg-white'" :language="pt" @closed="isValidDate()" :format="'dd/MM/yyyy'" placeholder="Selecione a data..."></datepicker>
                          <span v-if="errorDateStart"
                            class="font-weight-normal ml-1 text-danger" style="font-size: .7rem">Data Inválida</span>
                        </div>
                        <label class="p-0 mt-2 mr-1 ml-2">Até:</label>
                        <div class="d-flex flex-column">
                          <datepicker v-model="expiresIn" :input-class="'form-control bg-white'" @closed="isValidDate()" :language="pt" :format="'dd/MM/yyyy'" placeholder="Selecione a data..."></datepicker>
                          <span v-if="errorDateExpire"
                            class="font-weight-normal ml-1 text-danger" style="font-size: .7rem">Data Inválida</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <button class="btn btn-sm mt-2 button-back" type="button" @click="back()">Cancelar</button>
                      <button v-if="!loading" class="btn btn-sm mt-2 button-upload-image" type="button" @click="saveModal()">Salvar Modal</button>
                      <div v-else class="btn btn-sm mt-2 button-upload-image">
                        <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                      </div>
                    </div>
                </div>
                <div class="d-flex flex-column">
                  <label class="p-0 m-0">Modal Preview</label>
                  <div class="d-flex flex-column ml-4 modal-preview">
                      <img id="preview-image" src="@/assets/notifications/default_image_modal.png" class="img-fluid custom-image">
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    "select-companies": () => import("./SelectCompanies.vue"),
    Datepicker
  },

  props: {
    selectedNotification: Object
  },

  data() {
    return {
      file: '',
      sizeOutRange: false,
      typeOutRange: false,
      filterSelected: '',
      filterTypes: [{
        name: 'Empresas',
        value: 'companies'
      }, {
        name: 'Planos',
        value: 'plans'
      }],
      selectedBasic: '',
      selectedEssential: '',
      selectedAdvanced: '',
      selectedProfessional: '',
      notificationActive: false,
      redirectTo: '',
      startsIn: '',
      expiresIn: '',
      filePath: '',
      description: '',
      companies: [],
      companiesNotification: [],
      plans: [
        {
          name: 'basic',
          selected: false
        },
        {
          name: 'essential',
          selected: false
        },
        {
          name: 'advanced',
          selected: false
        },
        {
          name: 'professional',
          selected: false
        }
      ],
      showImage: false,
      pt: { 
        language: 'Brazilian', 
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'], 
        monthsAbbr: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'], 
        days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        yearSuffix: '' 
      },
      errorDateStart: false,
      errorDateExpire: false,
      loading: false,
      roleModal: []
    };
  },

  methods: {
    selectRoleModal(role) {
      if(!this.roleModal.includes(role)) {
        this.$set(this.roleModal, this.roleModal.length, role);
      } else {
        this.$delete(this.roleModal, this.roleModal.indexOf(role));
      }
    },

    /**
     * Metodo que processa upload de imagem
     */
    selectedImage: async function () {
      let inputBanner = document.querySelector('input[type=file]')
      this.file = document.querySelector('input[type=file]').files[0]
      let preview = document.querySelector('#preview-image')

      if (!this.file || this.file.size > 5 * 1024 * 1024) {
        inputBanner.value = ''
        this.file = '';
        return this.sizeOutRange = true
      }
      this.sizeOutRange = false

      let imageType = ["image/png", "image/jpeg", "image/jpg"].find(type => type === this.file.type)
      if (!imageType) {
        inputBanner.value = ''
        this.file = '';
        return this.typeOutRange = true
      }
      this.typeOutRange = false

      preview.src = await this.getUrlFromFile(this.file)
    },

    /**
     * Método que transforma arquivo em url base64
     * @param file arquivo para conversão
     */
    getUrlFromFile: function (file) {
      let reader = new FileReader()
      if (file) 
        reader.readAsDataURL(file)

      let url = new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
      return url
    },

    /**
     * Metodo para fazer upload da imagem
     * @param {File} file imagem a ser carregada
     */
    uploadImage: async function () {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('bucketDir', 'tallos-modal-chat-app');
      formData.append('fileType', 'image');

      this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

      return await this.$tallos_admin_api.post('upload/', formData)
          .then(response => {
            this.filePath = response.data.uploadedFile.file_path;
          })
          .catch(error => {
            console.log(error)
            this.$toasted.global.defaultError({message: "erro ao fazer upload", err: error})
          })
    },

    /**
     * Metodo para atualizar as empresas selecionadas
     * @param {Object} companies Objeto das empresas a serem adionadas
     */
    selectedCompanies(companies) {
      this.companies = companies;
    },

    /** 
     * Metodo para salvar as opções do modal
     * 
    */
    saveModal: async function() {
      this.loading = true;

      if (this.selectedNotification.length != 0 && this.file || this.selectedNotification.length == 0) {
        await this.uploadImage();
      }

      if (
        this.filePath
      ) {
        let url = '';
        let requestBody = {
            title: this.description,
            media: this.filePath,
            type: 'modal',
            starts_in: new Date(this.startsIn).toISOString(),
            expires_in: new Date(this.expiresIn).toISOString(),
            redirect_to: this.redirectTo,
            employee: this.$store.getters.admin.name,
            filters: {
              role: this.roleModal.length > 0 ? this.roleModal : ['all'],
              type: this.filterSelected,
              companies: this.companies,
              plans: this.plans,
            },
          };

        if (this.selectedNotification.length == 0) {
          url = 'notifications/create';
          requestBody['created_at'] = new Date().toISOString();
          requestBody['created_by'] = this.$store.getters.admin.id;
        } else {
          url = 'notifications/update';
          requestBody['_id'] = this.selectedNotification._id;
        }

        this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
				  `Bearer ${this.$store.getters.tokenJWT}`;

        return await this.$tallos_admin_api.post(url, requestBody)
            .then(response => {
              this.loading = false; 
              this.$toasted.global.defaultSuccess({msg: this.selectedNotification.length == 0 ? 'Notificação criada com sucesso' : 'Notificação atualizada com sucesso'});
              this.back();
            })
            .catch(error => {
              console.log(error)
              this.loading = false; 
              this.back();
              this.$toasted.global.defaultError({message: "erro ao fazer upload", err: error})
            })
      } else {
        this.loading = false; 
        this.$toasted.global.defaultError({message: "A imagem é obrigatória", err: ''})
      }
    },

    /** 
     * Metodo para voltar para a janela anterior
     * 
    */
    back() {
      this.$emit('back');
    },

    /**
     * Método para validar se a data escolhida está de acordo com os requisitos
     */
    isValidDate() {
      this.errorDateStart = false;
      this.errorDateExpire = false;

      if (!this.startsIn || !this.expiresIn) { 
        this.errorDateExpire = this.expiresIn ? false : true;
        this.errorDateStart = this.startsIn ? false : true;
        return false;
      } else if (
        this.convertToMilliseconds(this.startsIn) >
        this.convertToMilliseconds(this.expiresIn)
      ) {
        this.errorDateExpire = true;
        this.errorDateStart = true;
        return false;
      }
      return true;
    },

    /**
     * Converte a data para milissegundos
     */
    convertToMilliseconds(date) {
      return new Date(date).valueOf();
    }
  },

  created() {
    document.title = "Tallos Admin - Módulo CS";
  },

  mounted: async function() {
    if (this.selectedNotification) {
      this.filterSelected = this.selectedNotification.filters.type;
      this.redirectTo = this.selectedNotification.redirect_to;
      this.expiresIn = this.selectedNotification.expires_in;
      this.startsIn = this.selectedNotification.starts_in;
      this.filePath = this.selectedNotification.media;
      this.description = this.selectedNotification.title;
      this.companiesNotification = this.selectedNotification.filters.companies;
      this.plans = this.selectedNotification.filters.plans;

      let preview = document.querySelector('#preview-image');
      preview.src = this.filePath;
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #fff;
}

.badge {
  width: 100px;
}

.bg-voip {
  background: #9c27b0;
  color: #fff;
}

.lupa-style {
  position: absolute;
  right: 10px;
  top: 8px;
  color: #0008;
  cursor: pointer;
}

.input-search {
  border-radius: 3px !important;
}

.select-review-companies {
  width: 15px !important;
  color: #0009;
  background: #fff;
  border: none;
  outline: none !important;
}

.td-last-accsess {
	font-weight: 500;
	text-align: start;
}

.span-engaged-disengaged {
  display: flex;
  justify-content: center;
  align-items: center;
}

.engaged {
	font-weight: 600;
	font-size: 14px;
	color: rgb(40,167,69);
}

.disengaged {
	font-weight: 600;
	font-size: 14px;
	color: rgb(245, 81, 31);
}

.dropdown-menu li {
  outline: none;
  border: none;
  background: white;
  color: #0652DD;
  font-weight: 600;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #ddd;
}

.title-height {
  height: 600px;
}

.spinner-size {
   max-height: 20px; 
   max-width: 20px;
}

.container-upload-image {
  width: 300px;
}

.title-upload-image {
  font-size: .7rem;
}

.button-select-image {
  font-size: .8rem;
}

.alert-upload-image {
  font-size: .7rem;
}

.button-upload-image {
  width: 40%;
  background-color: #428bca;
  color: #fff;
  border-radius: 10px;
}

.button-back {
  width: 30%;
  background-color: #cfcfcf;
  color: #000000;
  border-radius: 10px;
}

.button-upload-image:hover {
  background-color: #1a67ac;
  border-color: #fff;
}

.modal-preview {
  background-color: #f1f1f1;
  width: 500px;
  height: 500px;
  margin-right: 70px;
}

.no-image-selected {
  margin-top: 230px;
  height: 100%;
  width: 100%;
  opacity: 90%;
}

.alert-icon {
  font-size: 25px;
}

.error-date {
  border-color: crimson;
  border-width: 1px;
}
</style>